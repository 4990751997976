import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { ValidationFormContext, VArray, VButton, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import { useMutation, useQuery } from "@shane32/graphql";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { XButton } from "../../../components/misc/XButton";
import SaleStatus from "../../../enums/SaleStatus";
import SaleStockType from "../../../enums/SaleStockType";
import StateCode from "../../../enums/StatesCode";
import FormatDate from "../../../helpers/FormatDate";
import Rounding from "../../../helpers/Rounding";
import StringHelper from "../../../helpers/StringHelper";

//Query for sales
interface ISaleQueryResult {
    sale: ISale;
    salesChannels: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
        }>;
    };
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            shippingCarrier: {
                name: string;
            };
        }>;
    };
}

interface ICreatePurchaseOrderFromSaleResult {
    sale: {
        createPurchaseOrderFromSale: number;
    };
}

interface ICreatePurchaseOrderFromSaleVariables {
    id: string;
    supplierId: string | null;
}

interface IUnitOfMeasureQueryResult {
    unitOfMeasures: {
        items: Array<IUnitOfMeasure>;
    };
}

interface IUnitOfMeasure {
    id: string;
    abbreviation: string;
}

interface IAddress {
    id: string;
    firstName: string | null;
    lastName: string | null;
    company: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    phone: string | null;
    countryId: string | null;
}

//interfaces for all the queries
interface ISale {
    id: string;
    salesChannelId: string | null;
    shippingServiceId: string | null;
    status: SaleStatus;
    stockType?: SaleStockType;
    orderDate: string | null;
    shipBy: string | null;
    salesChannelReference: string | null;
    email: string | null;
    subtotal: number;
    tax: number;
    customerReference: string | null;
    total: number;
    customerNotes: string | null;
    internalNotes: string | null;
    paidSubtotal: number;
    paidTax: number;
    taxPercentage: number;
    dropShip: boolean;
    shippingAddress: IAddress | null;
    billingAddress: IAddress | null;
    lineItems: Array<ILineItem>;
}

interface ILineItem {
    id: string;
    quantityBackordered: number;
    quantityPicked: number;
    quantityShipped: number;
    quantityPreviouslyShipped: number;
    quantityShippedSeparately: number;
    quantity: number;
    unitPrice: number;
    unitOfMeasureId: string;
    total: number;
    tax: number;
    description: string;
    comment: string;
    sku: string | null;
    isAdditionalCharge: boolean;
    sortOrder: number;
}

interface ILineItemModel {
    id: string;
    quantityBackordered: string;
    quantityPicked: string;
    quantityShipped: string;
    quantityPreviouslyShipped: string;
    quantityShippedSeparately: string;
    quantity: string;
    unitPrice: string;
    unitOfMeasureId: string;
    total: string;
    tax: string;
    description: string;
    comment: string;
    sku: string | null;
    isAdditionalCharge: boolean;
    sortOrder: string;
}

interface ICountryQueryResult {
    countries: {
        items: Array<ICountry>;
    };
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

//model for original modal
interface ISaleModel {
    id: string;
    salesChannelId: string | null;
    orderDate: string | null;
    shipBy: string | null;
    salesChannelReference: string | null;
    email: string | null;
    customerReference: string | null;
    shippingServiceId: string | null;
    customerNotes: string | null;
    internalNotes: string | null;
    dropShip: string;
    total: string;
    subtotal: string;
    tax: string;
    paidTax: string;
    paidSubtotal: string;
    paid: string;
    balanceDue: string;
    shippingFirstName: string | null;
    shippingLastName: string | null;
    shippingCompany: string | null;
    shippingAddress1: string | null;
    shippingAddress2: string | null;
    shippingCity: string | null;
    shippingState: string | null;
    shippingZip: string | null;
    shippingCountryId: string | null;
    shippingPhone: string | null;
    billingFirstName: string | null;
    billingLastName: string | null;
    billingCompany: string | null;
    billingAddress1: string | null;
    billingAddress2: string | null;
    billingCity: string | null;
    billingState: string | null;
    billingZip: string | null;
    billingCountryId: string | null;
    billingPhone: string | null;
    saleStatus: SaleStatus;
    lineItems: Array<ILineItemModel>;
    additionalCharges: Array<{
        id: string;
        quantityBackordered: string;
        quantityPicked: string;
        quantityShipped: string;
        quantityPreviouslyShipped: string;
        quantityShippedSeparately: string;
        quantity: string;
        unitPrice: string;
        unitOfMeasureId: string;
        total: string;
        tax: string;
        description: string;
        comment: string;
        sku: string | null;
        isAdditionalCharge: boolean;
        sortOrder: string;
    }>;
}

interface IEditResult {
    sale: {
        edit: ISale;
    };
}

interface IReshipResult {
    sale: {
        reship: ISale;
    };
}

interface IVoidResult {
    sale: {
        void: boolean;
    };
}

interface IEditVariables {
    original: ISale;
    modified: ISale;
}

interface IModal {
    show: boolean;
    original?: ISale;
}

const hiddenModal: IModal = {
    show: false,
};

interface IImportResult {
    api: {
        importExport: {
            import: {
                saleLineItems: ISale;
            };
        };
    };
}

interface IImportVariables {
    saleId: string;
    base64: string;
}

interface IExportResult {
    api: {
        importExport: {
            export: {
                saleLineItems: string;
            };
        };
    };
}

interface IExportVariables {
    saleId: string;
}

const saleInnerQuery = `
    id
    salesChannelId
    salesChannelReference
    email
    customerReference
    shippingServiceId
    shipBy
    orderDate
    status
    subtotal
    tax
    paidSubtotal
    paidTax
    taxPercentage
    internalNotes
    customerNotes
    total
    dropShip
    stockType
    shippingAddress {
        id
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        countryId
    }
    billingAddress {
        id
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        countryId
    }
    lineItems {
        id
        quantityPicked
        quantityShipped
        quantityBackordered
        quantityPreviouslyShipped
        quantityShippedSeparately
        quantity
        unitOfMeasureId
        unitPrice
        tax
        total
        description
        comment
        sku
        isAdditionalCharge
        sortOrder
    }
`;

const ImportCSV = `
mutation ($saleId: ID!, $base64: String!) {
  api {
    importExport {
      import {
        saleLineItems(saleId: $saleId, base64: $base64) {
          ${saleInnerQuery}
        }
      }
    }
  }
}
`;

const ExportCSV = `
mutation ($saleId: ID!) {
  api {
    importExport {
      export {
        saleLineItems(saleId: $saleId)
      }
    }
  }
}
`;

//Queries and Mutations
const SalesQuery = `
query ($id: ID!) {
    sale(id: $id) {
        ${saleInnerQuery}
    }
    salesChannels {
        items {
            id
            name
            active
        }
    }
    shippingServices {
        items {
            id
            name
            active
            shippingCarrierId
            shippingCarrier {
                name
            }
        }
    }
}
`;

const SalesMutation = `
mutation($original: SaleInput!, $modified: SaleInput!) {
    sale{
        edit (original: $original, modified: $modified)  {
            ${saleInnerQuery}
        }
    }
}
`;

const VoidMutation = `
mutation($id: ID!) {
    sale {
        void (id: $id)
    }
}
`;

const ReshipMutation = `
mutation($id: ID!) {
  sale {
    reship(id: $id) {
      id
    }
  }
}
`;

const CreatePurchaseOrderFromSaleMutation = `
mutation ($id: ID!, $supplierId: ID) {
  sale {
    createPurchaseOrderFromSale(id: $id, supplierId: $supplierId)
  }
}`;

const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

const SalesEdit = () => {
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<ISaleQueryResult, {}>(SalesQuery, { variables: { id: id }, fetchPolicy: "no-cache" });
    const {
        data: unitOfMeasuresData,
        error: unitOfMeasuresError,
        refetch: unitOfMeasuresRefetch,
    } = useQuery<IUnitOfMeasureQueryResult, {}>("{unitOfMeasures { items { id abbreviation } }}", { fetchPolicy: "no-cache" });
    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<ICountryQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(SalesMutation);
    const [runVoid] = useMutation<IVoidResult, { id: string }>(VoidMutation);
    const [runReship] = useMutation<IReshipResult, { id: string }>(ReshipMutation);
    const [runCreatePurchaseOrderFromSale] = useMutation<ICreatePurchaseOrderFromSaleResult, ICreatePurchaseOrderFromSaleVariables>(
        CreatePurchaseOrderFromSaleMutation
    );
    const [saving, setSaving] = React.useState(false);
    const history = useHistory();
    const [runImport] = useMutation<IImportResult, IImportVariables>(ImportCSV);
    const [runExport] = useMutation<IExportResult, IExportVariables>(ExportCSV);
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);
    const [inputFile, setInputFile] = React.useState<File | null>(null);
    const [fileError, setFileError] = React.useState<string>("");

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;
    if (unitOfMeasuresError) return <ErrorDisplay onClick={unitOfMeasuresRefetch}>{unitOfMeasuresError.message}</ErrorDisplay>;
    if (!data || !unitOfMeasuresData || !dataCountry) return <Loading />;

    //display message if failed to retrieve data
    if (!data.sale) return <ErrorDisplay onClick={refetch}>Sale ID #{id}</ErrorDisplay>;

    var taxPercentage = data.sale.taxPercentage;

    //=== set up local functions ===
    //run when the add button is pressed
    const onShowModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        setModal(hiddenModal);
        clearModal();
    };

    //clear the modal for the file import
    const clearModal = () => {
        if (inputFileRef && inputFileRef?.current) inputFileRef.current.value = "";
        setInputFile(null);
        setFileError("");
        setSaving(false);
    };

    //convert file to base64
    const convertBase64 = async (file: Blob) => {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                if (fileReader.result) {
                    //remove the metadata at the beginning
                    let encoded = fileReader.result.toString().replace(/^data:(.*,)?/, "");
                    if (encoded.length % 4 > 0) {
                        encoded += "=".repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onChangeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputFile(e.currentTarget.files[0]);
        }
    };

    //send Id to the server and return the base64 String
    const onImport = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputFile) {
            convertBase64(inputFile).then(
                (ret) => {
                    runImport({
                        variables: {
                            saleId: id,
                            base64: ret,
                        },
                    }).then(
                        (ret) => {
                            if (data.sale && ret.data) {
                                data.sale = ret.data?.api.importExport.import.saleLineItems;
                                onHideModal();
                                setSaving(false);
                                setInputFile(null);
                            }
                        },
                        (err) => {
                            console.error(`Error uploading Sale `, err);
                            setFileError(err.message);
                            setSaving(false);
                            setInputFile(null);
                        }
                    );
                },
                (err) => {
                    console.log(err.message);
                    setSaving(false);
                    setInputFile(null);
                }
            );
        }
    };

    const onExport = () => {
        runExport({
            variables: {
                saleId: id,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data?.api.importExport.export.saleLineItems;
                if (returnedData) {
                    var byteCharacters = atob(returnedData);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    var fileURL = URL.createObjectURL(file);
                    var fileLink = document.createElement(`a`);
                    fileLink.href = fileURL;
                    fileLink.download = `SO-${id} LineItems`;
                    fileLink.click();
                }
            },
            (err) => {
                console.error(`Error trying to export LineItems`, err);
                alert("Error trying to export data");
            }
        );
    };

    //consts to hold data that is changing live
    const lineItemTotal = (obj: ILineItemModel) => {
        return Rounding.EvenRound(parseFloat(obj.quantity) * parseFloat(obj.unitPrice));
    };

    const subtotal = (obj: ISaleModel) => {
        var filteredLineItems = obj.lineItems.filter((x) => !StringHelper.IsNullOrWhitespace(x.sku));
        var filteredAdditionalCharges = obj.additionalCharges.filter((x) => !StringHelper.IsNullOrWhitespace(x.sku));
        return Rounding.EvenRound(
            filteredLineItems
                .map((lineItem) => parseFloat(lineItem.quantity) * parseFloat(lineItem.unitPrice))
                .reduce((sum, current) => sum + current, 0) +
                filteredAdditionalCharges
                    .map((additionalCharge) => parseFloat(additionalCharge.quantity) * parseFloat(additionalCharge.unitPrice))
                    .reduce((sum, current) => sum + current, 0)
        );
    };

    const usCountry = dataCountry.countries.items.filter((x) => x.isoCode === "US");
    let usCountryId: string | null = null;
    if (usCountry && usCountry.length > 0) usCountryId = usCountry[0].id;

    const originalModel: ISaleModel = {
        id: data.sale.id,
        salesChannelId: data.sale.salesChannelId || "",
        orderDate: StringHelper.IsNullOrWhitespace(data.sale.orderDate)
            ? null
            : FormatDate.ConvertDateToDateTimePicker(data?.sale.orderDate),
        shipBy: StringHelper.IsNullOrWhitespace(data.sale.shipBy) ? null : data?.sale.shipBy,
        salesChannelReference: data.sale.salesChannelReference || "",
        email: data.sale.email,
        customerReference: data.sale.customerReference || "",
        shippingServiceId: data.sale.shippingServiceId || "",
        customerNotes: data.sale.customerNotes || "",
        internalNotes: data.sale.internalNotes || "",
        dropShip: data.sale.dropShip.toString(),
        total: data.sale.total.toFixed(2),
        subtotal: data.sale.subtotal.toFixed(2),
        tax: data.sale.tax.toFixed(2),
        paidTax: data.sale.paidTax?.toFixed(2),
        paidSubtotal: data.sale.paidSubtotal?.toFixed(2),
        paid: (data.sale.paidTax + data.sale.paidSubtotal).toFixed(2),
        balanceDue: (data.sale.total - (data.sale.paidTax + data.sale.paidSubtotal)).toFixed(2),
        saleStatus: data.sale.status,
        shippingFirstName: data.sale.shippingAddress?.firstName || "",
        shippingLastName: data.sale.shippingAddress?.lastName || "",
        shippingCompany: data.sale.shippingAddress?.company || "",
        shippingAddress1: data.sale.shippingAddress?.address1 || "",
        shippingAddress2: data.sale.shippingAddress?.address2 || "",
        shippingCity: data.sale.shippingAddress?.city || "",
        shippingState: data.sale.shippingAddress?.state || "",
        shippingZip: data.sale.shippingAddress?.zip || "",
        shippingCountryId: data.sale.shippingAddress?.countryId || usCountryId || "",
        shippingPhone: data.sale.shippingAddress?.phone || "",
        billingFirstName: data.sale.billingAddress?.firstName || "",
        billingLastName: data.sale.billingAddress?.lastName || "",
        billingCompany: data.sale.billingAddress?.company || "",
        billingAddress1: data.sale.billingAddress?.address1 || "",
        billingAddress2: data.sale.billingAddress?.address2 || "",
        billingCity: data.sale.billingAddress?.city || "",
        billingState: data.sale.billingAddress?.state || "",
        billingZip: data.sale.billingAddress?.zip || "",
        billingCountryId: data.sale.billingAddress?.countryId || usCountryId || "",
        billingPhone: data.sale.billingAddress?.phone || "",
        lineItems: data.sale.lineItems
            .filter((x) => !x.isAdditionalCharge)
            .map((lineItem) => ({
                id: lineItem.id,
                quantityBackordered: lineItem.quantityBackordered.toString(),
                quantityPicked: lineItem.quantityPicked.toString(),
                quantityShipped: lineItem.quantityShipped.toString(),
                quantityPreviouslyShipped: lineItem.quantityPreviouslyShipped.toString(),
                quantityShippedSeparately: lineItem.quantityShippedSeparately.toString(),
                quantity: lineItem.quantity.toFixed(2),
                unitPrice: lineItem.unitPrice.toFixed(2),
                unitOfMeasureId: lineItem.unitOfMeasureId,
                total: lineItem.total.toFixed(2),
                tax: lineItem.tax.toFixed(2),
                description: lineItem.description,
                comment: lineItem.comment,
                sku: lineItem.sku,
                isAdditionalCharge: false,
                sortOrder: lineItem.sortOrder.toString(),
            })),
        additionalCharges: data.sale.lineItems
            .filter((x) => x.isAdditionalCharge)
            .map((lineItem) => ({
                id: lineItem.id,
                quantityBackordered: lineItem.quantityBackordered.toString(),
                quantityPicked: lineItem.quantityPicked.toString(),
                quantityShipped: lineItem.quantityShipped.toString(),
                quantityPreviouslyShipped: lineItem.quantityPreviouslyShipped.toString(),
                quantityShippedSeparately: lineItem.quantityShippedSeparately.toString(),
                quantity: lineItem.quantity.toString(),
                unitPrice: lineItem.unitPrice.toFixed(2),
                unitOfMeasureId: lineItem.unitOfMeasureId,
                total: lineItem.total.toFixed(2),
                tax: lineItem.tax.toFixed(2),
                description: lineItem.description,
                comment: lineItem.comment,
                sku: lineItem.sku,
                isAdditionalCharge: true,
                sortOrder: lineItem.sortOrder.toString(),
            })),
    };

    const onSaveChanges = (modified: ISaleModel) => {
        //remove a key from the model before sending into graphql
        delete data.sale.stockType;
        //set form controls
        setSaving(true);
        //start add operation via graphql mutation
        const shippingAddressIsEmpty =
            StringHelper.IsNullOrWhitespace(modified.shippingFirstName) &&
            StringHelper.IsNullOrWhitespace(modified.shippingLastName) &&
            StringHelper.IsNullOrWhitespace(modified.shippingCompany) &&
            StringHelper.IsNullOrWhitespace(modified.shippingAddress1) &&
            StringHelper.IsNullOrWhitespace(modified.shippingAddress2) &&
            StringHelper.IsNullOrWhitespace(modified.shippingCity) &&
            StringHelper.IsNullOrWhitespace(modified.shippingState) &&
            StringHelper.IsNullOrWhitespace(modified.shippingZip) &&
            StringHelper.IsNullOrWhitespace(modified.shippingCountryId) &&
            StringHelper.IsNullOrWhitespace(modified.shippingPhone);
        const billingAddressIsEmpty =
            StringHelper.IsNullOrWhitespace(modified.billingFirstName) &&
            StringHelper.IsNullOrWhitespace(modified.billingLastName) &&
            StringHelper.IsNullOrWhitespace(modified.billingCompany) &&
            StringHelper.IsNullOrWhitespace(modified.billingAddress1) &&
            StringHelper.IsNullOrWhitespace(modified.billingAddress2) &&
            StringHelper.IsNullOrWhitespace(modified.billingCity) &&
            StringHelper.IsNullOrWhitespace(modified.billingState) &&
            StringHelper.IsNullOrWhitespace(modified.billingZip) &&
            StringHelper.IsNullOrWhitespace(modified.billingCountryId) &&
            StringHelper.IsNullOrWhitespace(modified.billingPhone);
        const filteredLineItems = modified.lineItems.filter((x) => !StringHelper.IsNullOrWhitespace(x.sku));
        const filteredAdditionalCharges = modified.additionalCharges.filter((x) => !StringHelper.IsNullOrWhitespace(x.sku));
        const total = Rounding.EvenRound(subtotal(modified) * (1 + taxPercentage));
        const paid = parseFloat(modified.paid);
        const paidSubtotalPercentage = paid < total ? paid / total : 0;
        const paidSubtotal = paid < total ? Rounding.EvenRound(paidSubtotalPercentage * subtotal(modified)) : subtotal(modified);
        const paidTax = paid - paidSubtotal;
        runEdit({
            variables: {
                original: data.sale,
                modified: {
                    id: data.sale.id,
                    salesChannelId: StringHelper.IsNullOrWhitespace(modified.salesChannelId) ? null : modified.salesChannelId,
                    orderDate: StringHelper.IsNullOrWhitespace(modified.orderDate) ? null : FormatDate.ConvertDateToUTC(modified.orderDate),
                    shipBy: StringHelper.IsNullOrWhitespace(modified.shipBy) ? null : FormatDate.ConvertToDateType(modified.shipBy),
                    salesChannelReference: StringHelper.IsNullOrWhitespace(modified.salesChannelReference)
                        ? null
                        : modified.salesChannelReference,
                    email: StringHelper.IsNullOrWhitespace(modified.email) ? null : modified.email,
                    customerReference: StringHelper.IsNullOrWhitespace(modified.customerReference) ? null : modified.customerReference,
                    shippingServiceId: StringHelper.IsNullOrWhitespace(modified.shippingServiceId) ? null : modified.shippingServiceId,
                    customerNotes: StringHelper.IsNullOrWhitespace(modified.customerNotes) ? null : modified.customerNotes,
                    internalNotes: StringHelper.IsNullOrWhitespace(modified.internalNotes) ? null : modified.internalNotes,
                    dropShip: modified.dropShip === "true",
                    total: Rounding.EvenRound(subtotal(modified) * (1 + taxPercentage)),
                    status: modified.saleStatus,
                    subtotal: subtotal(modified),
                    tax: Rounding.EvenRound(subtotal(modified) * taxPercentage),
                    paidSubtotal: paidSubtotal,
                    paidTax: paidTax,
                    taxPercentage: taxPercentage,
                    shippingAddress: shippingAddressIsEmpty
                        ? null
                        : {
                              id: data.sale.shippingAddress?.id ?? "0",
                              firstName: StringHelper.IsNullOrWhitespace(modified.shippingFirstName) ? null : modified.shippingFirstName,
                              lastName: StringHelper.IsNullOrWhitespace(modified.shippingLastName) ? null : modified.shippingLastName,
                              company: StringHelper.IsNullOrWhitespace(modified.shippingCompany) ? null : modified.shippingCompany,
                              address1: StringHelper.IsNullOrWhitespace(modified.shippingAddress1) ? null : modified.shippingAddress1,
                              address2: StringHelper.IsNullOrWhitespace(modified.shippingAddress2) ? null : modified.shippingAddress2,
                              city: StringHelper.IsNullOrWhitespace(modified.shippingCity) ? null : modified.shippingCity,
                              state: StringHelper.IsNullOrWhitespace(modified.shippingState) ? null : modified.shippingState,
                              zip: StringHelper.IsNullOrWhitespace(modified.shippingZip) ? null : modified.shippingZip,
                              countryId: StringHelper.IsNullOrWhitespace(modified.shippingCountryId)
                                  ? usCountryId
                                      ? usCountryId
                                      : null
                                  : modified.shippingCountryId,
                              phone: StringHelper.IsNullOrWhitespace(modified.shippingPhone) ? null : modified.shippingPhone,
                          },
                    billingAddress: billingAddressIsEmpty
                        ? null
                        : {
                              id: data.sale.billingAddress?.id ?? "0",
                              firstName: StringHelper.IsNullOrWhitespace(modified.billingFirstName) ? null : modified.billingFirstName,
                              lastName: StringHelper.IsNullOrWhitespace(modified.billingLastName) ? null : modified.billingLastName,
                              company: StringHelper.IsNullOrWhitespace(modified.billingCompany) ? null : modified.billingCompany,
                              address1: StringHelper.IsNullOrWhitespace(modified.billingAddress1) ? null : modified.billingAddress1,
                              address2: StringHelper.IsNullOrWhitespace(modified.billingAddress2) ? null : modified.billingAddress2,
                              city: StringHelper.IsNullOrWhitespace(modified.billingCity) ? null : modified.billingCity,
                              state: StringHelper.IsNullOrWhitespace(modified.billingState) ? null : modified.billingState,
                              zip: StringHelper.IsNullOrWhitespace(modified.billingZip) ? null : modified.billingZip,
                              countryId: StringHelper.IsNullOrWhitespace(modified.billingCountryId)
                                  ? usCountryId
                                      ? usCountryId
                                      : null
                                  : modified.billingCountryId,
                              phone: StringHelper.IsNullOrWhitespace(modified.billingPhone) ? null : modified.billingPhone,
                          },
                    lineItems: [
                        ...filteredLineItems.map((lineItem) => ({
                            id: lineItem.id || "0",
                            quantityBackordered: parseFloat(lineItem.quantityBackordered) || 0,
                            quantityPicked: parseFloat(lineItem.quantityPicked) || 0,
                            quantityShipped: parseFloat(lineItem.quantityShipped) || 0,
                            quantityPreviouslyShipped: parseFloat(lineItem.quantityPreviouslyShipped) || 0,
                            quantityShippedSeparately: parseFloat(lineItem.quantityShippedSeparately) || 0,
                            quantity: parseFloat(lineItem.quantity),
                            unitPrice: parseFloat(lineItem.unitPrice),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            total: lineItemTotal(lineItem) + Rounding.EvenRound(lineItemTotal(lineItem) * taxPercentage),
                            tax: Rounding.EvenRound(lineItemTotal(lineItem) * taxPercentage),
                            description: lineItem.description || "",
                            comment: lineItem.comment,
                            sku: lineItem.sku,
                            isAdditionalCharge: false,
                            sortOrder: parseFloat(lineItem.sortOrder) || 1,
                        })),
                        ...filteredAdditionalCharges.map((lineItem) => ({
                            id: lineItem.id || "0",
                            quantityBackordered: parseFloat(lineItem.quantityBackordered) || 0,
                            quantityPicked: parseFloat(lineItem.quantityPicked) || 0,
                            quantityShipped: parseFloat(lineItem.quantityShipped) || 0,
                            quantityPreviouslyShipped: parseFloat(lineItem.quantityPreviouslyShipped) || 0,
                            quantityShippedSeparately: parseFloat(lineItem.quantityShippedSeparately) || 0,
                            quantity: parseFloat(lineItem.quantity),
                            unitPrice: parseFloat(lineItem.unitPrice),
                            unitOfMeasureId: lineItem.unitOfMeasureId,
                            total: lineItemTotal(lineItem) + Rounding.EvenRound(lineItemTotal(lineItem) * taxPercentage),
                            tax: Rounding.EvenRound(lineItemTotal(lineItem) * taxPercentage),
                            description: lineItem.description || "",
                            comment: lineItem.comment,
                            sku: lineItem.sku,
                            isAdditionalCharge: true,
                            sortOrder: parseFloat(lineItem.sortOrder) || 1,
                        })),
                    ],
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                data.sale = ret.data.sale.edit;
                setSaving(false);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding sales", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const onReship = () => {
        if (!window.confirm("Are you sure you want to reship this sale order?")) return;

        setSaving(true);

        runReship({
            variables: {
                id: id,
            },
        }).then(
            (ret) => {
                let path = `/sales/${ret.data.sale.reship.id}`;
                history.push(path);
                setSaving(false);
            },
            (err) => {
                setSaving(false);
                console.error("Error reshipping sale", err);
                alert(err.message);
            }
        );
    };

    const onCreatePurchaseOrderFromSale = () => {
        if (!window.confirm("Are you sure you want to create a purchase order from this sale?")) return;

        if (data.sale.stockType === SaleStockType.Stock) {
            alert("Cannot create a purchase order from a stock sale");
            return;
        }

        setSaving(true);

        runCreatePurchaseOrderFromSale({
            variables: {
                id: id,
                supplierId: null,
            },
        }).then(
            (ret) => {
                setSaving(false);
                alert("Created po for this sale");
            },
            (err) => {
                setSaving(false);
                console.error("Error creating po", err);
                alert(err.message);
            }
        );
    };

    const onVoid = () => {
        if (!window.confirm("Are you sure you want to void this sale order?")) return;
        //set form controls
        setSaving(true);
        //start add operation via graphql mutation
        runVoid({
            variables: {
                id: id,
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                let path = `/sales`;
                history.push(path);
                setSaving(false);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding sales", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const CopyShippingAddress = () => {
        const validationFormContext = React.useContext(ValidationFormContext);

        const copy = () => {
            const shippingFirstName: string = validationFormContext.currentValue["shippingFirstName"];
            const shippingLastName: string = validationFormContext.currentValue["shippingLastName"];
            const shippingCompany: string = validationFormContext.currentValue["shippingCompany"];
            const shippingAddress1: string = validationFormContext.currentValue["shippingAddress1"];
            const shippingAddress2: string = validationFormContext.currentValue["shippingAddress2"];
            const shippingCity: string = validationFormContext.currentValue["shippingCity"];
            const shippingState: string = validationFormContext.currentValue["shippingState"];
            const shippingZip: string = validationFormContext.currentValue["shippingZip"];
            const shippingCountryId: string = validationFormContext.currentValue["shippingCountryId"];
            const shippingPhone: string = validationFormContext.currentValue["shippingPhone"];

            validationFormContext.onChange("billingFirstName", shippingFirstName);
            validationFormContext.onChange("billingLastName", shippingLastName);
            validationFormContext.onChange("billingCompany", shippingCompany);
            validationFormContext.onChange("billingAddress1", shippingAddress1);
            validationFormContext.onChange("billingAddress2", shippingAddress2);
            validationFormContext.onChange("billingCity", shippingCity);
            validationFormContext.onChange("billingState", shippingState);
            validationFormContext.onChange("billingZip", shippingZip);
            validationFormContext.onChange("billingCountryId", shippingCountryId);
            validationFormContext.onChange("billingPhone", shippingPhone);
        };

        return (
            <Button type="button" variant="btn btn-outline-light btn-sm py-0" onClick={copy}>
                Copy Shipping Address
            </Button>
        );
    };

    const states = Object.values<string>(StateCode);
    states.unshift("");
    const saleStatuses = Object.values(SaleStatus).filter((v) => isNaN(Number(v)));

    return (
        <>
            <div>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={data.sale.id}>
                    <Row>
                        <Col xxl={8}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    <div>General</div>
                                    <div>
                                        <VSelect fieldName="saleStatus">
                                            {saleStatuses.map((s) => (
                                                <option key={s} value={s}>
                                                    {s}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="salesChannelId">
                                                <VLabel column sm={4}>
                                                    Sales Channel
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {data.salesChannels.items
                                                            .filter((x) => x.active)
                                                            .map((salesChannels) => (
                                                                <option key={`sales-${salesChannels.id}`} value={salesChannels.id}>
                                                                    {salesChannels.name}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="salesChannelReference">
                                                <VLabel column sm={4}>
                                                    Sales Channel Ref
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="orderDate">
                                                <VLabel column sm={4}>
                                                    Order Date
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="datetime-local" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="shipBy">
                                                <VLabel column sm={4}>
                                                    Ship By
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="date" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="shippingServiceId">
                                                <VLabel column sm={4}>
                                                    Shipping Method
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {data.shippingServices.items
                                                            .filter((x) => x.active)
                                                            .map((shippingService) => (
                                                                <option key={`sales-${shippingService.id}`} value={shippingService.id}>
                                                                    {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="email">
                                                <VLabel column sm={4}>
                                                    Email
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="dropShip">
                                                <VLabel column sm={4}>
                                                    Drop Ship
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value={"true"}>Yes</option>
                                                        <option value={"false"}>No</option>
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="customerReference">
                                                <VLabel column sm={4}>
                                                    Customer Ref
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="customerNotes">
                                                <VLabel column sm={4}>
                                                    Customer Notes
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-2">
                                            <VGroup as={Row} fieldName="internalNotes">
                                                <VLabel column sm={4} lg={2}>
                                                    Notes
                                                </VLabel>
                                                <Col sm={8} lg={10}>
                                                    <VControl type="textarea" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row>
                                <Col lg={6}>
                                    <Card className="border-primary">
                                        <Card.Header className="bg-primary text-white">Shipping Address</Card.Header>
                                        <Card.Body>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingFirstName">
                                                <VLabel column sm={4}>
                                                    First Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingLastName">
                                                <VLabel column sm={4}>
                                                    Last Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingCompany">
                                                <VLabel column sm={4}>
                                                    Company
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingAddress1">
                                                <VLabel column sm={4}>
                                                    Address 1
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingAddress2">
                                                <VLabel column sm={4}>
                                                    Address 2
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingCity">
                                                <VLabel column sm={4}>
                                                    City
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingState">
                                                <VLabel column sm={4}>
                                                    State
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option></option>
                                                        {states.map((state) => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingZip">
                                                <VLabel column sm={4}>
                                                    Zip
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingPhone">
                                                <VLabel column sm={4}>
                                                    Phone
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingCountryId">
                                                <VLabel column sm={4}>
                                                    Country
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        {dataCountry.countries.items
                                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                                            .map((country) => (
                                                                <option key={`sales-${country.id}`} value={country.id}>
                                                                    {country.isoCode}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className="border-primary">
                                        <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                            <div>Billing Address</div>
                                            <CopyShippingAddress />
                                        </Card.Header>
                                        <Card.Body>
                                            <VGroup as={Row} className="mb-2" fieldName="billingFirstName">
                                                <VLabel column sm={4}>
                                                    First Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingLastName">
                                                <VLabel column sm={4}>
                                                    Last Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingCompany">
                                                <VLabel column sm={4}>
                                                    Company
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingAddress1">
                                                <VLabel column sm={4}>
                                                    Address 1
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingAddress2">
                                                <VLabel column sm={4}>
                                                    Address 2
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingCity">
                                                <VLabel column sm={4}>
                                                    City
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingState">
                                                <VLabel column sm={4}>
                                                    State
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option></option>
                                                        {states.map((state) => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingZip">
                                                <VLabel column sm={4}>
                                                    Zip
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingPhone">
                                                <VLabel column sm={4}>
                                                    Phone
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingCountryId">
                                                <VLabel column sm={4}>
                                                    Country
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        {dataCountry.countries.items
                                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                                            .map((country) => (
                                                                <option key={`sales-${country.id}`} value={country.id}>
                                                                    {country.isoCode}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Summary</Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Subtotal</td>
                                                <td>
                                                    {/*change subtotal box live whenever price and quantity change*/}
                                                    {/*get current changed data to display correctly*/}
                                                    <VControl
                                                        type="text"
                                                        fieldName="subtotal"
                                                        dynamicValue={(obj: ISaleModel) => Rounding.EvenRound(subtotal(obj)).toFixed(2)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tax</td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        fieldName="tax"
                                                        dynamicValue={(obj: ISaleModel) =>
                                                            Rounding.EvenRound(subtotal(obj) * taxPercentage).toFixed(2)
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Total</b>
                                                </td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        fieldName="total"
                                                        dynamicValue={(obj: ISaleModel) =>
                                                            Rounding.EvenRound(subtotal(obj) * (1 + taxPercentage)).toFixed(2)
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Paid</td>

                                                <td>
                                                    <VControl fieldName="paid" required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Balance Due</td>
                                                <td>
                                                    {
                                                        <VControl
                                                            type="text"
                                                            fieldName="balanceDue"
                                                            dynamicValue={(obj: ISaleModel) =>
                                                                Rounding.EvenRound(
                                                                    subtotal(obj) * (1 + taxPercentage) - parseFloat(obj.paid)
                                                                ).toFixed(2)
                                                            }
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <p>
                                <VButton type="submit" variant="primary">
                                    Save Changes
                                </VButton>
                            </p>
                            <p>
                                <VButton type="button" variant="danger" onClick={onVoid} disabled={data.sale.status === "VOID"}>
                                    Void
                                </VButton>
                            </p>
                            <p>
                                <VButton type="button" variant="secondary" onClick={onReship} disabled={data.sale.status !== "COMPLETED"}>
                                    Reship
                                </VButton>
                            </p>
                            <p className={data.sale.stockType === SaleStockType.DropShip ? "" : "d-none"}>
                                <VButton
                                    type="button"
                                    variant="secondary"
                                    onClick={onCreatePurchaseOrderFromSale}
                                    disabled={data.sale.status !== "AUTHORIZED"}
                                >
                                    Create purchase order
                                </VButton>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    Products & Services
                                </Card.Header>
                                <Card.Body>
                                    <p>Products</p>
                                    <div>
                                        <Button variant="outline-secondary" onClick={onShowModal}>
                                            Import
                                        </Button>
                                        <Button variant="outline-secondary" className="mx-3" onClick={onExport}>
                                            Export
                                        </Button>
                                    </div>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Product Sku</th>
                                                <th>Comment</th>
                                                <th>Quantity Ordered</th>
                                                <th>Quantity Picked</th>
                                                <th>Quantity Already Shipped</th>
                                                <th>Quantity Backordered</th>
                                                <th>Quantity Shipped Separately</th>
                                                <th>Unit</th>
                                                <th>Price</th>
                                                <th>Tax</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/*user VArray to loop all the data that is held inside lineItems and hold data as it changes live*/}
                                            <VArray
                                                fieldName="lineItems"
                                                blankRow={{
                                                    sku: "",
                                                    comment: "",
                                                    quantity: "",
                                                    quantityPicked: 0,
                                                    quantityShipped: 0,
                                                    quantityBackordered: 0,
                                                    quantityShippedSeparately: 0,
                                                    unitOfMeasureId: "",
                                                    unitPrice: "",
                                                    tax: "",
                                                    total: "",
                                                }}
                                            >
                                                <tr>
                                                    <td>
                                                        <VControl type="text" fieldName="sku" required />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="comment" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantity" required />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityPicked" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityShipped" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityBackordered" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityShippedSeparately" disabled />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="unitOfMeasureId" required>
                                                            <option value=""></option>
                                                            {unitOfMeasuresData.unitOfMeasures.items.map((unit) => (
                                                                <option key={unit.id} value={unit.id}>
                                                                    {unit.abbreviation}
                                                                </option>
                                                            ))}
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="unitPrice" required />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="tax"
                                                            dynamicValue={(obj: ILineItemModel) =>
                                                                Rounding.EvenRound(lineItemTotal(obj) * taxPercentage).toFixed(2)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="total"
                                                            dynamicValue={(obj: ILineItemModel) =>
                                                                Rounding.EvenRound(lineItemTotal(obj) * (1 + taxPercentage)).toFixed(2)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <XButton />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                    <p>Additional Charges and Services</p>
                                    <div className="gap-2">
                                        <Button variant="outline-success" className="me-2">
                                            +
                                        </Button>
                                        <Button variant="outline-secondary">Get Shipping Rates</Button>
                                    </div>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Product Sku</th>
                                                <th>Comment</th>
                                                <th>Quantity</th>
                                                <th>Quantity Picked</th>
                                                <th>Quantity Already Shipped</th>
                                                <th>Quantity Backordered</th>
                                                <th>Quantity Shipped Separately</th>
                                                <th>Unit</th>
                                                <th>Price</th>
                                                <th>Tax</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray
                                                fieldName="additionalCharges"
                                                blankRow={{
                                                    sku: "",
                                                    comment: "",
                                                    quantity: "",
                                                    quantityPicked: 0,
                                                    quantityShipped: 0,
                                                    quantityBackordered: 0,
                                                    quantityShippedSeparately: 0,
                                                    unitOfMeasureId: "",
                                                    unitPrice: "",
                                                    tax: "",
                                                    total: "",
                                                }}
                                            >
                                                <tr>
                                                    <td>
                                                        <VControl type="text" fieldName="sku" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="comment" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantity" required />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityPicked" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityShipped" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityBackordered" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantityShippedSeparately" disabled />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="unitOfMeasureId" required>
                                                            <option value=""></option>
                                                            {unitOfMeasuresData.unitOfMeasures.items.map((unit) => (
                                                                <option key={unit.id} value={unit.id}>
                                                                    {unit.abbreviation}
                                                                </option>
                                                            ))}
                                                        </VSelect>
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="unitPrice" required />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="tax"
                                                            dynamicValue={(obj: ILineItemModel) =>
                                                                Rounding.EvenRound(lineItemTotal(obj) * taxPercentage).toFixed(2)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="total"
                                                            dynamicValue={(obj: ILineItemModel) =>
                                                                Rounding.EvenRound(lineItemTotal(obj) * (1 + taxPercentage)).toFixed(2)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <XButton />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </VForm>
            </div>
            <Modal show={modal.show} onHide={onHideModal}>
                <form onSubmit={onImport}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="csvFile" className="mb-2">
                            <Form.Label>Import CSV</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xls, .xlsx"
                                required
                                className="primary"
                                onChange={onChangeImportFile}
                                ref={inputFileRef}
                            />
                        </Form.Group>
                        <Button variant="primary" className="mb-2" type="submit" disabled={saving || inputFile === null}>
                            Upload
                        </Button>
                        <Form.Group className="mb-2">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" readOnly value={fileError} rows={5} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={clearModal}>
                            Clear
                        </Button>
                        <Button variant="secondary" onClick={onHideModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default SalesEdit;
